import Spacer from "@src/components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import {
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { FC } from "react";
import Text from "@ui/text";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";

type Props = {
  icon: DeprecatedIconType;
  title: string;
  subtitle?: string;
  loading?: boolean;
  iconSize?: number;
  iconColor?: DeprecateTone;
};

const EmptyState: FC<Props> = ({
  icon,
  iconSize = 64,
  iconColor = deprecatedTones.gray5,
  title,
  subtitle,
  loading = false,
}) => {
  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      direction="vertical"
      alignmentVertical="center"
      alignmentHorizontal="center"
      padding={80}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <DeprecatedIcon
            type={icon}
            containerSize={iconSize}
            iconSize={iconSize}
            color={iconColor}
          />
          <Spacer size={8} />
          <Text
            type="P3"
            fontWeight="Medium"
            color={deprecatedTones.gray7}
            multiline
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              type="P3"
              fontWeight="Medium"
              color={deprecatedTones.gray5}
              multiline
              style={{ textAlign: "center" }}
            >
              {subtitle}
            </Text>
          )}
        </>
      )}
    </AutoLayout>
  );
};

export default EmptyState;
